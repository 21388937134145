<template>
  <div>
    <div
      class="d-flex"
      style="align-items: center; justify-content: space-between;"
    >
      <div
        class="d-flex align-center"
        style="gap: 10px;"
      >
        <v-icon>
          {{ icons.mdiPlaylistPlay }}
        </v-icon>
        <span>Mídias</span>
      </div>
      <div
        class="d-flex align-center"
        style="gap: 20px;"
      >
        <v-autocomplete
          v-model="searchPlaylist"
          style="width: 300px;"
          label="Buscar por playlist"
          item-text="name"
          item-value="name"
          hide-details
          outlined
          dense
          :items="playList"
          :append-icon="icons.mdiMagnify"
          @change="filterPlayList"
        />
        <v-btn
          color="info"
          outlined
          class="px-4"
          @click="openStoreModal"
        >
          <v-icon>{{ icons.mdiPlaylistPlus }}</v-icon>
          <span class="pl-2">NOVA PLAYLIST</span>
        </v-btn>
      </div>
    </div>
    <div>
      <CardPlaylist
        v-if="newPlaylist.length > 0"
        :key="updatedPlaylist"
        :get-playlist="getPlayList"
        :playlist="filteredPlayList"
      />
      <div
        v-else
        style="height: 600px; font-weight: 500; gap: 27px;"
        class="d-flex align-center justify-center flex-column"
      >
        <div class="d-flex flex-column">
          <span
            style="font-size: 18px; font-weight: 500"
            :class="{'dark-mode': isDark, 'light-mode': !isDark}"
          >Nenhuma playlist encontrada!</span>
          <span
            style="font-size: 14px; font-weight: 500"
            :class="{'dark-mode': isDark, 'light-mode': !isDark}"
          >Por favor, tente criar uma playlist.</span>
        </div>
        <img
          :src="computedSrc"
          alt="Imagem de um bonequinho com uma lupa procurando por dados em um servidor"
        >
      </div>
    </div>
    <v-dialog
      v-model="isStoreModalVisible"
      max-width="512px"
      max-height="329px"
      persistent
    >
      <StorePlaylistModal
        :get-new-playlist="getPlayList"
        @close="closeStoreModal"
      />
    </v-dialog>
  </div>
</template>

<script>
import { getVuetify } from '@/@core/utils'
import noDataImageLight from '@/assets/images/svg/no-data-logs-light.svg'
import noDataImageDark from '@/assets/images/svg/no-data-logs.svg'
import messages from '@/plugins/showMessageMixin'
// eslint-disable-next-line import/no-cycle
import axiosNewInstance from '@/plugins/newAxiosInstance'
import { mdiMagnify, mdiPlaylistPlay, mdiPlaylistPlus } from '@mdi/js'
import CardPlaylist from './CardPlaylist.vue'
import StorePlaylistModal from './modals/StorePlaylistModal.vue'

export default {
  name: 'InsertMedia',
  components: {
    CardPlaylist, StorePlaylistModal,
  },
  mixins: [messages],
  data() {
    return {
      icons: {
        mdiPlaylistPlay, mdiMagnify, mdiPlaylistPlus,
      },
      searchPlaylist: '',
      playList: [],
      filteredPlayList: [],
      isStoreModalVisible: false,
      updatedPlaylist: '',
      newPlaylist: [],
    }
  },
  computed: {
    isDark() {
      const $vuetify = getVuetify()

      return $vuetify.theme.dark
    },
    computedSrc() {
      return this.isDark ? noDataImageDark : noDataImageLight
    },
  },
  created() {
    this.getPlayList()
  },
  methods: {
    filterPlayList() {
      const searchQuery = this.searchPlaylist?.toLowerCase()

      if (!searchQuery) {
        this.filteredPlayList = this.newPlaylist
      } else {
        this.filteredPlayList = this.newPlaylist.filter(playlist => playlist?.name?.toLowerCase() === searchQuery.toLowerCase())
      }
    },
    async getPlayList() {
      try {
        const response = await axiosNewInstance.get('/api/v1/utilities/playlist/index')
        this.playList = response.data.data
        const newArr = this.playList.map((item, index) => ({ ...item, codeSelf: index + 1 }))
        console.log(newArr)
        this.newPlaylist = newArr
        this.filteredPlayList = this.newPlaylist
        this.updatedPlaylist = this.playList.updated_at
      } catch (error) {
        this.showMessage({
          title: 'Erro!', icon: 'error', text: 'Erro ao buscar as playlists.', timer: 4000,
        })
      }
    },
    openStoreModal() {
      this.isStoreModalVisible = true
    },
    closeStoreModal() {
      this.isStoreModalVisible = false
    },
  },
}
</script>

<style scoped>
.dark-mode {
  color: #e7e3fc;
  font-size: 14px;
  font-weight: 500
}
.light-mode {
  color: #312d4b;
  font-size: 14px;
  font-weight: 500
}
</style>

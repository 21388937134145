<template>
  <v-card style="border-radius: 10px;">
    <div class="d-flex align-center justify-center pt-2">
      <v-card-title primary-title>
        Editar mídia
      </v-card-title>
    </div>

    <v-card-text>
      <separator label="Atualizar mídia"></separator>
    </v-card-text>

    <v-card-text>
      <v-form
        ref="form"
        v-model="isFormValid"
        class="pt-4"
      >
        <v-container class="pa-0 ma-0">
          <v-row
            class="pa-0 ma-0"
            style="gap: 20px;"
          >
            <v-col class="pa-0 ma-0">
              <v-autocomplete
                v-model="localItem.type"
                :items="['Imagem', 'Vídeo']"
                dense
                outlined
                label="Tipo"
                :rules="[v => !!v || 'Tipo é obrigatório']"
                @change="switchType"
              ></v-autocomplete>
            </v-col>
            <v-col class="pa-0 ma-0">
              <v-autocomplete
                v-model="localItem.priorities"
                :items="['Alta', 'Média', 'Baixa']"
                dense
                outlined
                label="Prioridade"
                :rules="[v => !!v || 'Prioridade é obrigatória']"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-col
              class="pa-0 ma-0"
              cols="12"
            >
              <v-text-field
                v-model="localItem.name"
                dense
                outlined
                label="Nome"
                :rules="[v => !!v || 'Nome é obrigatório']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row
            v-if="localItem.type === 'Vídeo'"
            class="pa-0 ma-0"
          >
            <v-col
              class="pa-0 ma-0"
              cols="12"
            >
              <v-text-field
                v-model="localItem.link"
                dense
                outlined
                label="Link Youtube"
                :rules="[v => !!v || 'Link Youtube é obrigatório']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row
            v-if="localItem.type == 'Imagem'"
            class="pa-0 ma-0"
          >
            <v-col
              class="pa-0 ma-0"
              cols="12"
            >
              <v-file-input
                v-model="fileList"
                :prepend-inner-icon="icons.mdiPaperclip"
                prepend-icon=""
                label="Atualizar imagem"
                outlined
                multiple
                small-chips
                dense
              />
            </v-col>
          </v-row>
          <v-row
            v-if="localItem.type == 'Imagem'"
            class="pa-0 ma-0"
          >
            <v-col
              class="pa-0 ma-0"
              cols="12"
            >
              <v-chip
                outlined
                color="primary"
                class="mb-4"
              >
                {{ localItem.image_name }}
              </v-chip>
            </v-col>
          </v-row>

          <div
            class="d-flex align-center pb-3"
            style="gap: 14px;"
          >
            <v-icon
              color="purple"
              size="20px"
            >
              {{ icons.mdiCalendarClock }}
            </v-icon>
            <h4 style="font-weight: 600; font-size: 16px; line-height: 17px;">
              Período de Exibição
            </h4>
          </div>

          <v-row
            class="pa-0 ma-0"
            style="gap: 20px;"
          >
            <v-col class="pa-0 ma-0">
              <v-menu
                ref="menuStart"
                v-model="menuStart"
                :close-on-content-click="false"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="formattedStartDate"
                    label="Data inicial"
                    dense
                    outlined
                    readonly
                    v-bind="attrs"
                    :append-icon="icons.mdiCalendar"
                    hide-details
                    :rules="[v => !!v || 'Data inicial é obrigatória']"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  locale="pt-br"
                  no-title
                  scrollable
                  @input="updateStartDate"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menuStart = false"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="saveStartDate"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="pa-0 ma-0">
              <v-menu
                ref="menuEnd"
                v-model="menuEnd"
                :close-on-content-click="false"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="formattedEndDate"
                    label="Data final"
                    dense
                    outlined
                    readonly
                    v-bind="attrs"
                    :append-icon="icons.mdiCalendar"
                    hide-details
                    :rules="[v => !!v || 'Data final é obrigatória']"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  locale="pt-br"
                  no-title
                  scrollable
                  @input="updateEndDate"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menuEnd = false"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="saveEndDate"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="error"
        width="123px"
        style="height: 42px !important;"
        class="mr-2"
        :disabled="isLoading"
        @click="closeModal"
      >
        CANCELAR
      </v-btn>
      <v-btn
        color="info"
        style="height: 42px !important;"
        width="123px"
        :disabled="isLoading"
        @click="submitForm"
      >
        ATUALIZAR
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axiosNewInstance from '@/plugins/newAxiosInstance'
import messages from '@/plugins/showMessageMixin'
import { mdiCalendar, mdiCalendarClock, mdiPaperclip } from '@mdi/js'
import moment from 'moment'
import separator from '../../../../../../../components/Separator.vue'

export default {
  name: 'EditMediaModal',
  components: {
    separator,
  },
  mixins: [messages],
  props: {
    item: {
      type: Object,
      required: true,
    },
    getNewPlaylist: {
      type: Function,
      required: true,
    },
    getMediasByPlaylistId: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      mediaType: '',
      localItem: { ...this.item },
      mediaImage: null,
      mediaVideoUrl: '',
      fileList: [],
      isLoading: false,
      mediaName: '',
      mediaPriority: '',
      startDate: this.item.initial_date ? moment(this.item.initial_date).format('YYYY-MM-DD') : null,
      endDate: this.item.final_date ? moment(this.item.final_date).format('YYYY-MM-DD') : null,
      menuStart: false,
      menuEnd: false,
      isFormValid: false,
      icons: {
        mdiCalendarClock,
        mdiCalendar,
        mdiPaperclip,
      },
    }
  },
  computed: {
    formattedStartDate() {
      return this.startDate ? moment(this.startDate).format('DD/MM/YYYY') : ''
    },
    formattedEndDate() {
      return this.endDate ? moment(this.endDate).format('DD/MM/YYYY') : ''
    },
  },
  methods: {
    switchType() {
      this.mediaImage = null
      this.mediaVideoUrl = ''
    },
    closeModal() {
      this.$emit('close')
      this.mediaType = ''
      this.mediaImage = null
      this.mediaVideoUrl = ''
      this.mediaName = ''
      this.mediaPriority = ''
      this.startDate = null
      this.endDate = null
      this.menuStart = false
      this.menuEnd = false
      this.isFormValid = false
    },
    submitForm() {
      if (this.$refs.form.validate()) {
        this.storeMedia()
      }
    },
    async storeMedia() {
      const body = {
        type: this.localItem.type,
        priorities: this.localItem.priorities,
        name: this.localItem.name,
        link: this.localItem.link,
        initial_date: this.startDate ? moment(this.startDate).format('YYYY-MM-DD') : null,
        final_date: this.endDate ? moment(this.endDate).format('YYYY-MM-DD') : null,
        playlists_id: this.item.playlists_id,
      }

      try {
        this.isLoading = true
        await axiosNewInstance.put(`/api/v1/utilities/media/update/${this.item.id}`, body)
        if (this.fileList.length > 0) {
          const formData = new FormData()
          this.fileList.forEach(file => {
            formData.append('files[]', file)
          })
          formData.append('id', this.item.id)
          console.log(formData)
          await axiosNewInstance.post('/api/v1/utilities/media/submit_files', formData)
        }

        this.showMessage({
          title: 'Sucesso!', icon: 'success', text: 'Mídia inserida.', timer: 3000,
        })

        this.$emit('update')
        this.closeModal()
      } catch (error) {
        this.showMessage({
          title: 'Erro!', icon: 'error', text: 'Ocorreu algum erro, contate a equipe de desenvolvimento!', timer: 5000,
        })
      } finally {
        this.isLoading = false
      }
    },

    updateStartDate(date) {
      this.startDate = date
    },
    saveStartDate() {
      this.localItem.initial_date = this.startDate
      this.menuStart = false
    },
    updateEndDate(date) {
      this.endDate = date
    },
    saveEndDate() {
      this.localItem.final_date = this.endDate
      this.menuEnd = false
    },
  },
}
</script>

<style scoped>
</style>

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"d-flex pt-8 pb-4",staticStyle:{"align-items":"center","justify-content":"center"}},[_c('span',{},[_vm._v("Nova Playlist")])]),_c(VCardText,[_c('separator',{attrs:{"label":"Criar playlist"}})],1),_c(VCardText,{staticClass:"pb-0"},[_c(VForm,{ref:"form",staticClass:"pt-4",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{attrs:{"dense":"","outlined":"","label":"Nome","rules":[_vm.rules.required]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c(VTextField,{staticClass:"pt-1",attrs:{"dense":"","outlined":"","label":"Descrição","rules":[_vm.rules.required]},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"mr-2",attrs:{"color":"error","width":"123px","disabled":_vm.isLoading},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c(VBtn,{attrs:{"color":"success","width":"123px","disabled":_vm.isLoading},on:{"click":_vm.storePlaylist}},[_vm._v(" Salvar ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
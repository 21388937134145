<template>
  <div class="mt-8">
    <v-card-text class="pa-0">
      <v-card
        v-for="(item) in playlist"
        :key="item.id"
        style="justify-content: space-between;"
        class="d-flex align-center py-8 px-2 mb-4"
      >
        <div>
          <div
            class=" d-flex pl-14"
            style="gap: 80px; align-items: center;"
          >
            <h1
              style="width: 40px; font-size: 22px; font-weight: 500; line-height: 25.5px; color: #8C57FF !important;"
            >
              #{{ item.codeSelf }}
            </h1>
            <div
              class="d-flex flex-column"
              style="gap: 5px;"
            >
              <h2>{{ item.name.toUpperCase() }}</h2>
              <span class="pb-3">{{ item.description }}</span>
              <div
                class="d-flex"
                style="gap: 5px;"
              >
                <v-icon color="purple">
                  {{ icons.mdiLayersOutline }}
                </v-icon>
                <span>Mídias: </span>
                <span>{{ item.media_count }}</span>
              </div>
            </div>
          </div>
        </div>
        <v-card-actions
          class="d-flex pa-0 mr-8"
          style="align-items: center;"
        >
          <v-btn
            icon
            @click="deletePlaylist(deleteEndpoint, item.id)"
          >
            <v-icon color="error">
              {{ icons.mdiTrashCan }}
            </v-icon>
          </v-btn>
          <v-btn icon>
            <v-icon
              color="info"
              @click="openModalPlaylistEdit(item)"
            >
              {{ icons.mdiPencil }}
            </v-icon>
          </v-btn>
          <v-btn
            icon
            @click="openMediaModal(item)"
          >
            <v-icon>
              {{ icons.mdiEye }}
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card-text>
    <v-dialog
      v-model="isVisible"
      max-width="512px"
      max-height="329px"
      persistent
    >
      <EditPlaylistModal
        :key="modalKey"
        :item="selectedItem"
        :get-new-playlist="getPlaylist"
        @close="closeModal"
      />
    </v-dialog>
    <v-dialog
      v-model="isMediaModalVisible"
      max-width="688px"
      height="472px"
      max-height="940px"
      persistent
    >
      <MediaModal
        :key="modalKey"
        :item="selectedItem"
        :get-new-playlist="getPlaylist"
        :change-key="generateKey"
        @closeModal="closeMediaModal"
      />
    </v-dialog>
  </div>
</template>

<script>
import formatters from '@/plugins/formattersMixin1'
import {
  mdiEye,
  mdiLayersOutline,
  mdiPencil,
  mdiTrashCan,
} from '@mdi/js'
import EditPlaylistModal from './modals/EditPlaylistModal.vue'
import MediaModal from './modals/MediaModal.vue'

export default {
  name: 'CardPlaylist',
  components: {
    EditPlaylistModal, MediaModal,
  },
  mixins: [formatters],
  props: {
    playlist: {
      type: Array,
      required: true,
    },
    getPlaylist: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isVisible: false,
      selectedItem: {},
      modalKey: null,
      isMediaModalVisible: false,

      deleteEndpoint: '/api/v1/utilities/playlist/destroy',
      icons: {
        mdiLayersOutline, mdiTrashCan, mdiPencil, mdiEye,
      },
    }
  },
  methods: {
    async deletePlaylist(endpoint, id) {
      try {
        await this.deleteRegister(endpoint, id, 'Aviso!', 'Ao clicar em sim todas as mídias serão apagadas. Deseja continuar?')
        this.getPlaylist()
      } catch (error) {
        this.showMessage({
          title: 'Erro!', icon: 'error', text: 'Ocorreu um erro ao apagar a playlist.', timer: 4000,
        })
      }
    },
    openModalPlaylistEdit(item) {
      this.selectedItem = item
      this.modalKey = Math.floor(Math.random() * 100) + 1
      this.isVisible = true
    },
    closeModal() {
      this.isVisible = false
    },
    generateKey() {
      return Math.floor(Math.random() * 100) + 1
    },
    openMediaModal(item) {
      this.selectedItem = item
      this.modalKey = this.generateKey()
      this.isMediaModalVisible = true
    },
    closeMediaModal() {
      this.isMediaModalVisible = false
    },
  },

}

</script>

<style scoped>

</style>

<template>
  <v-card style="border-radius: 10px;">
    <div class="d-flex align-center justify-center pt-2">
      <v-card-title primary-title>
        Inserir mídia
      </v-card-title>
    </div>

    <v-card-text>
      <separator label="Criar mídia"></separator>
    </v-card-text>

    <v-card-text>
      <v-form
        ref="form"
        v-model="isFormValid"
        class="pt-4"
      >
        <v-container class="pa-0 ma-0">
          <v-row
            class="pa-0 ma-0"
            style="gap: 20px;"
          >
            <v-col class="pa-0 ma-0">
              <v-autocomplete
                v-model="mediaType"
                :items="['Imagem', 'Vídeo']"
                dense
                outlined
                label="Tipo"
                :rules="[v => !!v || 'Tipo é obrigatório']"
                @change="switchType"
              ></v-autocomplete>
            </v-col>
            <v-col class="pa-0 ma-0">
              <v-autocomplete
                v-model="mediaPriority"
                :items="['Alta', 'Média', 'Baixa']"
                dense
                outlined
                label="Prioridade"
                :rules="[v => !!v || 'Prioridade é obrigatória']"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-col
              class="pa-0 ma-0"
              cols="12"
            >
              <v-text-field
                v-model="mediaName"
                dense
                outlined
                label="Nome"
                :rules="[v => !!v || 'Nome é obrigatório']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row
            v-if="mediaType == 'Vídeo'"
            class="pa-0 ma-0"
          >
            <v-col
              class="pa-0 ma-0"
              cols="12"
            >
              <v-text-field
                v-model="mediaVideoUrl"
                dense
                outlined
                :prepend-inner-icon="icons.mdiLink"
                label="Link Youtube"
                :rules="[v => !!v || 'Link Youtube é obrigatório']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row
            v-if="mediaType == 'Imagem'"
            class="pa-0 ma-0"
          >
            <v-col
              class="pa-0 ma-0"
              cols="12"
            >
              <v-file-input
                v-model="fileList"
                :prepend-inner-icon="icons.mdiPaperclip"
                prepend-icon=""
                label="Inserir imagem"
                outlined
                multiple
                small-chips
                dense
              />
            </v-col>
          </v-row>

          <div
            class="d-flex align-center pb-3"
            style="gap: 14px;"
          >
            <v-icon
              color="purple"
              size="20px"
            >
              {{ icons.mdiCalendarClock }}
            </v-icon>
            <h4 style="font-weight: 600; font-size: 16px; line-height: 17px;">
              Período de Exibição
            </h4>
          </div>

          <v-row
            class="pa-0 ma-0"
            style="gap: 20px;"
          >
            <v-col class="pa-0 ma-0">
              <v-menu
                ref="menuStart"
                v-model="menuStart"
                :close-on-content-click="false"
                :return-value.sync="startDate"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="formattedStartDate"
                    label="Data inicial"
                    dense
                    outlined
                    readonly
                    v-bind="attrs"
                    :append-icon="icons.mdiCalendar"
                    hide-details
                    :rules="[v => !!v || 'Data inicial é obrigatória']"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  locale="pt-br"
                  no-title
                  scrollable
                  @change="(date) => { $refs.menuStart.save(date); startDate = date; }"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menuStart = false"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menuStart.save(startDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="pa-0 ma-0">
              <v-menu
                ref="menuEnd"
                v-model="menuEnd"
                :close-on-content-click="false"
                :return-value.sync="endDate"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="formattedEndDate"
                    label="Data final"
                    dense
                    outlined
                    readonly
                    v-bind="attrs"
                    :append-icon="icons.mdiCalendar"
                    hide-details
                    :rules="[v => !!v || 'Data final é obrigatória']"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  locale="pt-br"
                  no-title
                  scrollable
                  @change="(date) => { $refs.menuEnd.save(date); endDate = date; }"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menuEnd = false"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menuEnd.save(endDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="error"
        width="123px"
        style="height: 42px !important;"
        class="mr-2"
        :disabled="isLoading"
        @click="closeModal"
      >
        CANCELAR
      </v-btn>
      <v-btn
        color="info"
        style="height: 42px !important;"
        width="123px"
        :disabled="isLoading"
        @click="submitForm"
      >
        INSERIR
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axiosNewInstance from '@/plugins/newAxiosInstance'
import messages from '@/plugins/showMessageMixin'
import {
  mdiCalendar, mdiCalendarClock,
  mdiLink,
  mdiPaperclip,
} from '@mdi/js'
import moment from 'moment'
import separator from '../../../../../../../components/Separator.vue'

export default {
  name: 'MediaStoreModal',
  components: {
    separator,
  },
  mixins: [messages],
  props: {
    item: {
      type: Object,
      required: true,
    },
    getNewPlaylist: {
      type: Function,
      required: true,
    },
    getMediasByPlaylistId: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      localItem: { ...this.item },
      mediaType: '',
      mediaImage: null,
      mediaVideoUrl: '',
      mediaName: '',
      mediaPriority: '',
      startDate: null,
      isLoading: false,
      endDate: null,
      menuStart: false,
      menuEnd: false,
      isFormValid: false,
      fileList: [],
      icons: {
        mdiCalendarClock,
        mdiCalendar,
        mdiPaperclip,
        mdiLink,
      },
    }
  },
  computed: {
    formattedStartDate() {
      return this.startDate ? moment(this.startDate).format('DD/MM/YYYY') : ''
    },
    formattedEndDate() {
      return this.endDate ? moment(this.endDate).format('DD/MM/YYYY') : ''
    },
  },
  methods: {
    switchType() {
      this.mediaImage = null
      this.mediaVideoUrl = ''
    },
    closeModal() {
      this.$emit('close')
      this.mediaType = ''
      this.mediaImage = null
      this.mediaVideoUrl = ''
      this.mediaName = ''
      this.mediaPriority = ''
      this.startDate = null
      this.endDate = null
      this.menuStart = false
      this.menuEnd = false
      this.isFormValid = false
    },
    submitForm() {
      if (this.$refs.form.validate()) {
        this.storeMedia()
      }
    },
    async storeMedia() {
      const body = {
        type: this.mediaType,
        priorities: this.mediaPriority,
        name: this.mediaName,
        link: this.mediaVideoUrl,
        initial_date: this.startDate,
        final_date: this.endDate,
        playlists_id: this.item.id,
      }
      if (this.mediaType === 'Imagem') {
        try {
          this.isLoading = true
          const formData = new FormData()
          this.fileList.forEach(file => {
            formData.append('files[]', file)
          })
          formData.append('type', body.type)
          formData.append('priorities', body.priorities)
          formData.append('name', body.name)
          formData.append('link', body.link)
          formData.append('initial_date', body.initial_date)
          formData.append('final_date', body.final_date)
          formData.append('playlists_id', body.playlists_id)

          const response = await axiosNewInstance.post('/api/v1/utilities/media/full_store', formData)
          console.log(response)

          this.showMessage({
            title: 'Sucesso!', icon: 'success', text: 'Mídia inserida.', timer: 3000,
          })
          this.getNewPlaylist()
          this.getMediasByPlaylistId()
          this.closeModal()
        } catch (error) {
          console.log(error)
          this.showMessage({
            title: 'Erro!', icon: 'error', text: error.message, timer: 4000,
          })
        } finally {
          this.isLoading = false
        }

        return
      }
      try {
        await axiosNewInstance.post('/api/v1/utilities/media/store', body)
        this.showMessage({
          title: 'Sucesso!', icon: 'success', text: 'Mídia inserida.', timer: 3000,
        })
        this.getNewPlaylist()
        this.getMediasByPlaylistId()
        this.closeModal()
      } catch (error) {
        this.showMessage({
          title: 'Erro!', icon: 'error', text: error, timer: 4000,
        })
      }
    },

  },
}
</script>

<style scoped>
</style>

<template>
  <div
    style="width: 100%; height: 100%;"
    class="d-flex align-center justify-center pt-3"
  >
    <img
      v-if="itemsArray.type == 'Imagem'"
      width="100%"
      height="100%"
      :src="itemsArray.base64"
    >
    <youtube
      v-else
      :video-id="itemsArray.video_id"
      width="640"
      height="330"
      :player-vars="playerVars"
    >
    </youtube>
  </div>
</template>

<script>
export default {
  name: 'MediaRender',

  props: {
    itemsArray: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      playerVars: {
        autoplay: 1,
        vq: 'highres',
      },
    }
  },
}
</script>

<!-- eslint-disable vue/no-template-shadow -->
<template>
  <v-card>
    <div
      class="d-flex align-center justify-center pt-4 header-container"
      style=" min-width: 300px;"
    >
      <v-card-title
        class="title-container d-flex pt-2 pb-4"
        style="align-items: center; justify-content: center;"
      >
        <div
          class="d-flex align-start"
          style="gap: 10px;"
        >
          <div
            class="d-flex align-center"
            style="gap: 10px; flex-direction: column; width: 270px;"
          >
            <span
              style="font-weight: 600; font-size: 19px;"
            >
              <v-icon class="pr-1">
                {{ icons.mdiPlaylistPlay }}
              </v-icon>
              {{ localItem.name ? localItem.name.toUpperCase() : '' }}
            </span>
            <span style="font-weight: 600; font-size: 12px; line-height: 14.52px; margin-bottom: 5px;">{{ localItem.description }}</span>
            <div
              class=" d-flex align-center"
              style="gap: 14px;"
            >
              <v-icon
                style="background-color: #9A62FD26; height: 34px; width: 34px; border-radius: 3px;"
                color="purple"
              >
                {{ icons.mdiLayersOutline }}
              </v-icon>
              <span style="font-size: 13px; font-weight: 600; line-height: 16.5px;">Mídias: <span style="font-weight: 400; font-size: 11px">{{ localItem.media_count }}</span></span>
            </div>
          </div>
        </div>
      </v-card-title>
      <v-btn
        class="close-btn mr-0"
        style="margin-bottom: 90px;"
        icon
        @click="close"
      >
        <v-icon size="30">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
    <v-card-text
      v-if="actualItem.id"
      style="height: 320px; margin-bottom: 20px;"
    >
      <MediaRender
        :key="mediaRenderKey"
        :items-array="actualItem"
      />
      <!-- <NexusPlayer
        :key="nexusPlayerKey"
        :items-array="localItem"
      /> -->
    </v-card-text>
    <v-card-text>
      <v-btn
        color="info"
        outlined
        class="px-4"
        @click="openMediaStoreModal(item)"
      >
        <v-icon>{{ icons.mdiPlaylistPlus }}</v-icon>
        <span class="pl-2">INSERIR MÍDIA</span>
      </v-btn>
    </v-card-text>
    <v-card-text class="pb-0">
      <v-data-table
        :loading="isLoading"
        :items="tableData"
        disable-sort
        :headers="headers"
        loading-text="Carregando dados..."
        class="text-no-wrap custom-table"
        @click:row="handleClickRow"
      >
        <template
          v-slot:row="{item}"
        >
          <div :class="{'active-row': selectedItem === item}">
            {{ item }}
          </div>
        </template>

        <template v-slot:item.name="{ item, index }">
          <div
            class=" d-flex align-center"
            style="gap: 8px;"
          >
            <h4 style="width: 20px; font-weight: 500; font-size: 11px;">
              {{ index + 1 }}
            </h4>
            <div
              class="d-flex align-center"
              style="gap: 10px;"
              :class="{'isDisabled': !item.active, 'active-row': selectedItem === item, 'normal-row': item.active && selectedItem !== item}"
            >
              <div v-if="item.type == 'Imagem'">
                <v-icon>{{ icons.mdiImageOutline }}</v-icon>
              </div>
              <div v-else>
                <v-icon>{{ icons.mdiMovieOpenPlayOutline }}</v-icon>
              </div>
              <div
                class="d-flex align-start"
                style="flex-direction: column;"
              >
                <h4 style="font-weight: 600; font-size: 14px;">
                  {{ item.name }}
                </h4>
                <h4 style="font-weight: 500; font-size: 11px;">
                  {{ item.type }}
                </h4>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:item.priorities="{ item }">
          <div
            v-if="item.priorities === 'Alta'"
            style="width: 64px; padding: 3px 10px 3px 10px; border-radius: 50px;"
            class="d-flex align-center justify-center "
            :class="{'div-light-mode-alta': !isDark, 'div-dark-mode-alta':isDark, 'isDisabled': !item.active, 'active-row': selectedItem === item, 'normal-row': item.active && selectedItem !== item}"
          >
            <span
              style="font-weight: 600; font-size: 13px; "
              :class="{'h4-light-mode-alta': !isDark, 'h4-dark-mode-alta':isDark, 'isDisabled': !item.active, 'active-row': selectedItem === item, 'normal-row': item.active && selectedItem !== item}"
            >
              🔥Alta
            </span>
          </div>
          <div
            v-else-if="item.priorities === 'Média'"
            style=" width: 64px; padding: 3px 10px 3px 10px; border-radius: 50px;"
            class="d-flex align-center justify-center "
            :class="{'div-light-mode-media': !isDark, 'div-dark-mode-media':isDark, 'isDisabled': !item.active, 'active-row': selectedItem === item, 'normal-row': item.active && selectedItem !== item}"
          >
            <span
              style="font-weight: 600; font-size: 13px;  "
              :class="{'h4-light-mode-media': !isDark, 'h4-dark-mode-media':isDark, 'isDisabled': !item.active, 'active-row': selectedItem === item, 'normal-row': item.active && selectedItem !== item}"
            >
              {{ item.priorities }}
            </span>
          </div>
          <div
            v-else
            style=" width: 64px; padding: 3px 10px 3px 10px; border-radius: 50px;"
            class="d-flex align-center justify-center "
            :class="{'div-light-mode-baixa': !isDark, 'div-dark-mode-baixa':isDark, 'isDisabled': !item.active, 'active-row': selectedItem === item, 'normal-row': item.active && selectedItem !== item}"
          >
            <span
              style="font-weight: 600; font-size: 13px;  "
              :class="{'h4-light-mode-baixa': !isDark, 'h4-dark-mode-baixa':isDark, 'isDisabled': !item.active, 'active-row': selectedItem === item, 'normal-row': item.active && selectedItem !== item}"
            >
              {{ item.priorities }}
            </span>
          </div>
        </template>
        <template v-slot:item.options="{ item }">
          <span>
            <v-btn
              icon
              color="error"
              @click="removeMedia(item.id)"
            ><v-icon>{{ icons.mdiTrashCan }}</v-icon></v-btn>
            <v-btn
              icon
              color="info"
              @click="openEditMediaModal(item)"
            ><v-icon>{{ icons.mdiPencil }}</v-icon></v-btn>
          </span>
        </template>

        <template v-slot:no-data>
          <div
            style="height: 300px; font-weight: 500; gap: 27px;"
            class="d-flex align-center justify-center flex-column"
          >
            <div class="d-flex flex-column">
              <span
                style="font-size: 18px; font-weight: 500"
                :class="{'dark-mode': isDark, 'light-mode': !isDark}"
              >Nenhuma mídia encontrada!</span>
              <span
                style="font-size: 14px; font-weight: 500"
                :class="{'dark-mode': isDark, 'light-mode': !isDark}"
              >Por favor, tente criar uma mídia.</span>
            </div>
            <img
              :src="computedSrc"
              alt="Imagem de um bonequinho com uma lupa procurando por dados em um servidor"
            >
          </div>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog
      v-model="isMediaStoreModalOpen"
      max-width="512px"
      height="476px"
      max-height="940px"
      persistent
    >
      <MediaStoreModal
        :key="modalKey"
        :item="item"
        :get-medias-by-playlist-id="getMediasByPlaylistID"
        :get-new-playlist="getNewPlaylist"
        @close="closeMediaStoreModal"
      />
    </v-dialog>
    <v-dialog
      v-model="isMediaEditModalOpen"
      max-width="512px"
      height="476px"
      max-height="940px"
      persistent
    >
      <EditMediaModal
        :key="modalKey"
        :item="selectedItem"
        :get-medias-by-playlist-id="getMediasByPlaylistID"
        :get-new-playlist="getNewPlaylist"
        @update="updateModal"
        @close="closeEditMediaModal"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { getVuetify } from '@/@core/utils'
import noDataImageLight from '@/assets/images/svg/no-data-logs-light.svg'
import noDataImageDark from '@/assets/images/svg/no-data-logs.svg'
import formatters from '@/plugins/formattersMixin1'
import axiosNewInstance from '@/plugins/newAxiosInstance'
import messages from '@/plugins/showMessageMixin'
import {
  mdiClose,
  mdiEye,
  mdiImageOutline,
  mdiLayersOutline,
  mdiMovieOpenPlayOutline,
  mdiPencil,
  mdiPlaylistPlay,
  mdiPlaylistPlus,
  mdiTrashCan,
} from '@mdi/js'

// import NexusPlayer from '../NexusPlayer.vue'
import MediaRender from '../MediaRender.vue'
import EditMediaModal from './EditMediaModal.vue'
import MediaStoreModal from './MediaStoreModal.vue'

export default {
  name: 'MediaModal',
  components: {
    MediaStoreModal, EditMediaModal, MediaRender, // NexusPlayer,
  },
  mixins: [messages, formatters],
  props: {
    item: {
      type: Object,
      required: true,
    },
    getNewPlaylist: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      isMediaStoreModalOpen: false,
      isMediaEditModalOpen: false,
      mediaRenderKey: '',
      actualItem: {},
      selectedItem: null,
      nexusPlayerKey: null,
      modalKey: null,
      endpointDelete: '/api/v1/utilities/media/destroy',
      icons: {
        mdiLayersOutline, mdiTrashCan, mdiPencil, mdiEye, mdiPlaylistPlay, mdiClose, mdiPlaylistPlus, mdiMovieOpenPlayOutline, mdiImageOutline,
      },
      rules: {
        required: value => !!value || 'Campo obrigatório',
      },
      headers: [
        { text: 'MÍDIAS', value: 'name', align: 'start' },
        { text: 'PRIORIDADE', value: 'priorities', align: 'left' },
        { text: 'OPÇÕES', value: 'options', align: 'center' },
      ],
      playlist: [],
      tableData: [],
      localItem: {},
    }
  },
  computed: {
    isDark() {
      const $vuetify = getVuetify()

      return $vuetify.theme.dark
    },
    computedSrc() {
      return this.isDark ? noDataImageDark : noDataImageLight
    },
    getPlaylist() {
      return this.playlist
    },
  },
  created() {
    this.getMediasByPlaylistID()
  },
  methods: {
    close() {
      this.$emit('closeModal')
      this.localItem = { ...this.item }
      this.actualItem = {}
    },
    updateModal() {
      this.getNewPlaylist()
      this.getMediasByPlaylistID()
      this.nexusPlayerKey = Math.floor(Math.random() * 100) + 1
      this.modalKey = Math.floor(Math.random() * 100) + 1
      this.mediaRenderKey = (Math.floor(Math.random() * 100) + 1) / 2
    },
    async getMediasByPlaylistID() {
      try {
        this.isLoading = true
        const response = await axiosNewInstance.get(`/api/v1/utilities/playlist/edit/${this.item.id}`)
        this.localItem = response.data.data
        this.nexusPlayerKey = this.localItem.media_count
        this.tableData = response.data.data.media
      } catch (error) {
        this.showMessage({
          title: 'Erro!', icon: 'error', text: 'Erro ao buscar playlist.', timer: 4000,
        })
      } finally {
        this.isLoading = false
      }
    },
    async removeMedia(id) {
      try {
        this.isLoading = true
        await this.deleteRegister(this.endpointDelete, id, 'Aviso!', 'Ao clicar em sim, a mídia será apagada. Deseja continuar?')
        this.$emit('changeKey')
        this.nexusPlayerKey = this.localItem.media_count
        this.getNewPlaylist()
        this.getMediasByPlaylistID()
      } catch (error) {
        this.showMessage({
          title: 'Erro!', icon: 'error', text: 'Erro ao deletar mídia.', timer: 4000,
        })
      } finally {
        this.isLoading = false
      }
    },
    handleClickRow(e, itemSelected) {
      this.selectedItem = e
      this.mediaRenderKey = itemSelected.index
      this.actualItem = e
    },
    openEditMediaModal(item) {
      this.selectedItem = item
      this.modalKey = Math.floor(Math.random() * 100) + 1
      this.nexusPlayerKey = this.localItem.media_count
      this.isMediaEditModalOpen = true
    },
    closeEditMediaModal() {
      this.isMediaEditModalOpen = false
    },
    openMediaStoreModal(item) {
      this.selectedItem = item
      this.modalKey = Math.floor(Math.random() * 100) + 1
      this.nexusPlayerKey = this.localItem.media_count
      this.isMediaStoreModalOpen = true
    },
    closeMediaStoreModal() {
      this.isMediaStoreModalOpen = false
    },
  },
}
</script>

<style>

.dark-mode {
  color: #e7e3fc;
  font-size: 14px;
  font-weight: 500
}
.light-mode {
  color: #312d4b;
  font-size: 14px;
  font-weight: 500
}

.h4-light-mode-alta{
  color: #ff4800 !important;
}
.div-light-mode-alta{
  background-color: #ff33001a;
}
.h4-light-mode-media{
  color: #ff9900 !important;
}
.div-light-mode-media{
  background-color: #ffa7241a;
}
.h4-light-mode-baixa{
  color: #16B1FF !important;
}
.div-light-mode-baixa{
  background-color: #00aaff25;
}
/*
  Dark Mode
*/
.h4-dark-mode-alta{
  color: #ff7300 !important;
}
.div-dark-mode-alta{
  background-color: #FFB4001A;
}
.h4-dark-mode-media{
  color: #FFE500 !important;
}
.div-dark-mode-media{
  background-color: #FFB4001A;
}
.div-dark-mode-baixa{
  background-color: #16B1FF1A;
}
.h4-dark-mode-baixa{
  color: #16B1FF !important;
}
.close-btn {
  margin-left: 9.6em;
}

.active-row {
  opacity: 1 !important;
}

.normal-row{
  opacity: 0.7 !important;
}

.isDisabled{
  opacity: 0.3 !important;
}

.title-container {
  margin-left: 9.5em;
}

@media (max-width: 725px) {
  .close-btn {
    margin-left: 8em;
  }
  .title-container {
    margin-left: 8em;
  }
}
@media (max-width: 670px) {
  .close-btn {
    margin-left: 7em;
  }
  .title-container {
    margin-left: 7em;
  }
}
@media (max-width: 636px) {
  .close-btn {
    margin-left: 6em;
  }
  .title-container {
    margin-left: 6em;
  }
}
@media (max-width: 602px) {
  .close-btn {
    margin-left: 4.5em;
  }
  .title-container {
    margin-left: 4.5em;
  }
}
@media (max-width: 552px) {
  .close-btn {
    margin-left: 2.5em;
  }
  .title-container {
    margin-left: 2.5em;
  }
  .header-container{
    min-width: 300px !important;
  }
}

@media (max-width: 480px) {
  .close-btn {
    margin-left: 2em;
  }
  .title-container {
    margin-left: 1.5em;
  }
  .header-container{
    min-width: 250px !important;
  }
}
@media (max-width: 350px) {
  .close-btn {
    margin-left: 1em;
  }
  .title-container {
    margin-left: 0.5em;
  }
  .header-container{
    min-width: 150px !important;
  }
}

</style>

<template>
  <v-card>
    <v-card-title
      class="d-flex pt-8 pb-4"
      style="align-items: center; justify-content: center;"
    >
      <span class="">Editar Playlist</span>
    </v-card-title>

    <v-card-text>
      <separator label="Atualizar playlist"></separator>
    </v-card-text>

    <v-card-text class="pb-0">
      <v-form
        ref="form"
        v-model="valid"
        class="pt-4"
      >
        <v-text-field
          v-model="localItem.name"
          dense
          outlined
          label="Nome"
          :rules="[rules.required]"
        ></v-text-field>
        <v-text-field
          v-model="localItem.description"
          dense
          outlined
          label="Descrição"
          class="pt-1"
          :rules="[rules.required]"
        ></v-text-field>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="error"
        width="123px"
        class="mr-2"
        :disabled="isLoading"
        @click="close"
      >
        Cancelar
      </v-btn>
      <v-btn
        color="success"
        width="123px"
        :disabled="isLoading"
        @click="editPlaylist"
      >
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axiosNewInstance from '@/plugins/newAxiosInstance'
import messages from '@/plugins/showMessageMixin'
import Separator from '../../../../../../../components/Separator.vue'

export default {
  name: 'EditPlaylistModal',
  components: {
    Separator,
  },
  mixins: [messages],
  props: {
    item: {
      type: Object,
      required: true,
    },
    getNewPlaylist: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      valid: false,
      localItem: { ...this.item },
      rules: {
        required: value => !!value || 'Campo obrigatório',
      },
    }
  },
  methods: {
    close() {
      this.$emit('close')
      this.localItem = { ...this.item }
    },
    async editPlaylist() {
      if (this.$refs.form.validate()) {
        const body = {
          name: this.localItem.name,
          description: this.localItem.description,
        }
        try {
          this.isLoading = true
          await axiosNewInstance.put(`/api/v1/utilities/playlist/update/${this.item.id}`, body)
          this.getNewPlaylist()
          this.showMessage({
            title: 'Sucesso!', icon: 'success', text: 'Os dados foram atualizados.', timer: 3000,
          })
        } catch (error) {
          console.log(error)
          this.showMessage({
            title: 'Erro!', icon: 'error', text: 'Erro ao atualizar dados.', timer: 4000,
          })
        } finally {
          this.isLoading = false
        }
        this.close()
      }
    },
  },
}
</script>
